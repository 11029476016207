import React, { useRef } from 'react';

import Header from '../../components/header';
import { useAdmin } from '../../contexts/admin';

function AdminLogin() {
    const adminCtx = useAdmin();

    const passwordInput = useRef(null);

    async function login() {
        const password = passwordInput.current.value;
        adminCtx.login(password);
    }

    return (
        <>
            <Header />
            <h2>Admin Login</h2>
            <input type="password" ref={passwordInput} />
            <button onClick={login}>Login</button>
        </>
    );
}

export default AdminLogin;
