import React, { useRef } from 'react';
import { FiSave } from 'react-icons/fi';

import Header from '../../components/header';

import api from '../../services/api';

function NewBug({ history, match, location }) {
    const titleInput = useRef(null);
    const detailsInput = useRef(null);

    const { app } = location.state || {
        app: {
            _id: match.params.appId,
        },
    };

    async function createBug() {
        const title = titleInput.current.value;
        const details = detailsInput.current.value;
        const newBug = {
            title,
            application: app._id,
        };
        if (details.length > 0) {
            newBug.details = [ details ];
        }
        await api.post(`/v0/apps/${app._id}/bugs`, newBug);
        history.push({
            pathname: `/apps/${app._id}/bugs`,
            state: { app },
        });
    }

    return (
        <>
            <Header
                left={{
                    pathname: `/apps/${app._id}/bugs`,
                    state: { app },
                }}
                right={<button className="icon-link" onClick={createBug}><FiSave /></button>}
            />
            <main className="main-content">
                <h2>Novo Bug - {app.name}</h2>
                <div className="input-group">
                    <input type="text" placeholder="Título" ref={titleInput} />
                </div>
                <div className="input-group">
                    <textarea placeholder="Detalhes" ref={detailsInput}></textarea>
                </div>
            </main>
        </>
    );
}

export default NewBug;