import React from 'react';

import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { AdminProvider } from './contexts/admin';

import Home from './pages/home';
import Bugs from './pages/bugs/index';
import NewBug from './pages/bugs/new';
import EditBug from './pages/bugs/edit';
import Suggestions from './pages/suggestions/index';
import NewSuggestion from './pages/suggestions/new';
import EditSuggestion from './pages/suggestions/edit';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/apps/:appId/bugs" component={Bugs} />
        <Route path="/apps/:appId/bugs/new" component={NewBug} />
        <Route path="/apps/:appId/bugs/edit" component={EditBug} />
        <Route exact path="/apps/:appId/suggestions" component={Suggestions} />
        <Route path="/apps/:appId/suggestions/new" component={NewSuggestion} />
        <Route path="/apps/:appId/suggestions/edit" component={EditSuggestion} />
        <Route path="/ovtf-sat" component={AdminProvider} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
