import React, { useState } from 'react';
import { FiSave, FiTrash, FiX } from 'react-icons/fi';

function UpsertApp({ app, cancel, save }) {
    const [urls, setUrls] = useState(app.urls);
    const [urlsDirty, setUrlsDirty] = useState(false);

    const urlTypes = [
        'api',
        'desktop',
        'mobile',
        'web',
    ];

    function addURL(event) {
        setUrls({
            ...urls,
            [event.target.value]: '',
        });
        if (!urlsDirty) {
            setUrlsDirty(true);
        }
    }

    function changeURL(event, type) {
        setUrls({
            ...urls,
            [type]: event.target.value,
        });
        if (!urlsDirty) {
            setUrlsDirty(true);
        }
    }

    function removeUrl(type) {
        delete urls[type];
        setUrls({
            ...urls
        });
        if (!urlsDirty) {
            setUrlsDirty(true);
        }
    }

    function saveApp() {
        if (urlsDirty) {
            app.urls = urls;
        }
        save();
    }

    return (
        <>
            <div className="admin-app-title">
                <input
                    style={{ fontSize: '1em', width: '10em' }}
                    placeholder="Application Name"
                    type="text"
                    defaultValue={app.name}
                    onChange={(event) => app.name = event.target.value}
                />
                <div className="admin-title-actions">
                    <input
                        type="checkbox"
                        id={`alpha-check-${app._id}`}
                        name={`alpha-check-${app._id}`}
                        defaultChecked={app.alpha}
                        onChange={(event) => app.alpha = event.target.checked}
                    />
                    <label htmlFor={`alpha-check-${app._id}`}>Alpha</label>
                    <button
                        className="icon-link"
                        title="Save"
                        onClick={saveApp}
                    >
                        <FiSave />
                    </button>
                    <button
                        className="icon-link"
                        title="Cancel"
                        onClick={cancel}
                    >
                        <FiX />
                    </button>
                </div>
            </div>
            <h3 className="app-subtitle">URLs</h3>
            {Object.keys({ ...urls }).map(key => (
                <div key={`${app._id}-${key}`} className="admin-edit-access-links">
                    <input className="url-key-input" type="text" disabled value={key} />
                    <input
                        className="url-input"
                        type="text"
                        placeholder="URL"
                        defaultValue={urls[key]}
                        onChange={(event) => changeURL(event, key)}
                    />
                    <button
                        className="icon-link"
                        title="Delete"
                        onClick={() => removeUrl(key)}
                    >
                        <FiTrash />
                    </button>
                </div>
            ))}
            {Object.keys({ ...urls }).length < urlTypes.length && (
                <div className="admin-edit-access-links">
                    <select
                        className="url-key-input"
                        onChange={addURL}
                    >
                        <option value="-1">type</option>
                        {urlTypes
                            .filter(type => !Object.keys({ ...urls }).includes(type))
                            .map(type => (
                                <option key={type} value={type}>{type}</option>
                            ))
                        }
                    </select>
                    <input
                        disabled
                        className="url-input"
                        type="text"
                        placeholder="URL"
                    />
                </div>
            )}
        </>
    )
}

export default UpsertApp;
