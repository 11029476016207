import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Header from '../components/header';

import api from '../services/api';

import './styles.css';

function Home() {
    const [apps, setApps] = useState([]);

    useEffect(() => {
        (async function() {
            const result = await api.get('/v0/apps');
            setApps(result.data.applications);
        })();
    }, []);

    return (
        <>
            <Header />
            {apps.map(app => (
                <div className="app-content" key={app._id}>
                    <h2 className="app-title">{app.name}</h2>
                    {app.urls ?
                        <>
                            <h3 className="app-subtitle">Links de Acesso</h3>
                            <div className="access-links">
                                {Object.keys(app.urls).map(key => (
                                    <a key={`${app._id}-${key}`} href={app.urls[key]}>{key}</a>
                                ))}
                            </div>
                        </>
                    : null}
                    <Link to={{
                        pathname: `/apps/${app._id}/bugs`,
                        state: { app },
                    }} className="page-link">
                        Bugs
                    </Link>
                    <Link to={{
                        pathname: `/apps/${app._id}/suggestions`,
                        state: { app },
                    }} className="page-link">
                        Sugestões
                    </Link>
                </div>
            ))}
        </>
    );
}

export default Home;