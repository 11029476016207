import React, { createContext, useContext, useState } from 'react';

import AdminDashboard from '../pages/admin/dashboard';
import AdminLogin from '../pages/admin/login';

import api from '../services/api';

const AdminContext = createContext({});

export const AdminProvider = () => {
    const [logged, setLogged] = useState(false);

    async function login(password) {
        try {
            const result = await api.post('/v0/admin/authenticate', { password });
            const { token } = result.data;
            api.defaults.headers['authorization'] = token;
            setLogged(true);
        }
        catch (err) {
            console.log(err);
        }
    }
    return (
        <AdminContext.Provider value={{ login }}>
            {logged ? <AdminDashboard /> : <AdminLogin />}
        </AdminContext.Provider>
    );
};

export function useAdmin() {
    return useContext(AdminContext);
};
