import React, { useState } from 'react';
import { FiTrash } from 'react-icons/fi';

import api from '../../services/api';

function Items({ appId, resource }) {
    const [itemsList, setItemsList] = useState([]);
    const [itemsReturned, setItemsReturned] = useState(false);

    const title = resource[0].toUpperCase() + resource.slice(1);

    async function getItems() {
        try {
            const result = await api.get(`/v0/apps/${appId}/${resource}`)
            setItemsList(result.data[resource]);
            setItemsReturned(true);
        }
        catch {}
    }

    async function deleteItem(item) {
        try {
            await api.delete(`/v0/${resource}/${item._id}`);
            setItemsList(itemsList.filter(otherItem => otherItem._id !== item._id));
        }
        catch {}
    }

    function setResolved(itemId, value) {
        api.put(`/v0/${resource}/${itemId}`, { resolved: value });
    }

    return itemsReturned
        ? (
            <>
                <h3>{title}</h3>
                {itemsList.length === 0
                    ? <span>No {resource} found</span>
                    : itemsList.map(item => (
                        <div key={item._id} className="admin-item">
                            <input
                                type="checkbox"
                                id={`resolved-check-${item._id}`}
                                name={`resolved-check-${item._id}`}
                                defaultChecked={!!item.resolved}
                                onChange={(event) => setResolved(item._id, event.target.checked)}
                            />
                            <span>{item.title}</span>
                            <button
                                className="icon-link"
                                title="Delete"
                                onClick={() => deleteItem(item)}
                            >
                                <FiTrash />
                            </button>
                        </div>
                    ))
                }
            </>
        )
        : (
            <button
                className="link-btn"
                onClick={getItems}
            >
                {title}
            </button>
        );
}

export default Items;
