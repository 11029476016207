import React from 'react';
import { Link } from 'react-router-dom';
import { FiChevronLeft, FiPlus } from 'react-icons/fi';

function Header({ left, right }) {
    return (
        <header className="header">
            {left ? <Link className="icon-link" to={left}><FiChevronLeft /></Link> : <div></div>}
            <h1>SAT 0.2.0</h1>
            {right ? (right.type === 'button' ?
                right
                :
                <Link
                    className="icon-link"
                    to={right}
                >
                    <FiPlus />
                </Link>)
            : <div></div>}
        </header>
    );
}

export default Header;