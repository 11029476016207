import React, { useEffect, useRef, useState } from 'react';
import { FiSave } from 'react-icons/fi';

import Header from '../../components/header';

import api from '../../services/api';

function EditBug({ history, match, location }) {
    const detailsInput = useRef(null);
    const [details, setDetails] = useState([]);

    const { app } = location.state || {
        app: {
            _id: match.params.appId,
        },
    };
    const { bug } = location.state || {};

    useEffect(() => {
        if (bug && bug.details) {
            setDetails(bug.details);
        }
    }, []);

    if (!bug) {
        history.push({
            pathname: `/apps/${app._id}/bugs`,
            state: { app },
        });

        return null;
    }

    async function editBug() {
        const newDetail = detailsInput.current.value;
        if (newDetail.length > 0) {
            const newDetails = [ ...details, newDetail ];
            await api.put(`/v0/bugs/${bug._id}`, { details: newDetails });
            detailsInput.current.value = '';
            setDetails(newDetails);
        }
    }

    return (
        <>
            <Header
                left={{
                    pathname: `/apps/${app._id}/bugs`,
                    state: { app },
                }}
                right={<button className="icon-link" onClick={editBug}><FiSave /></button>}
            />
            <main className="main-content">
                <h2>Bug - {app.name}</h2>
                <div className="item-content">
                    <h3>{bug && bug.title}</h3>
                    {details.map((detail, index) => (
                        <pre key={index}>{detail}</pre>
                    ))}
                </div>
                <div className="input-group">
                    <textarea placeholder="Novo detalhe" ref={detailsInput}></textarea>
                </div>
            </main>
        </>
    );
}

export default EditBug;