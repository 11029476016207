import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/header';

import api from '../../services/api';

function Bugs({ match, location }) {
    const [bugs, setBugs] = useState([]);

    const { app } = location.state || {
        app: {
            _id: match.params.appId,
        },
    };

    useEffect(() => {
        (async function() {
            const result = await api.get(`/v0/apps/${app._id}/bugs`);
            setBugs(result.data.bugs);
        })()
    }, []);

    return (
        <>
            <Header
                left="/"
                right={{
                    pathname: 'bugs/new',
                    state: { app },
                }}
            />
            <main className="main-content">
                <h2>Bugs - {app.name}</h2>
                {bugs
                    .filter(bug => !bug.resolved)
                    .map(bug => (
                        <h3 key={bug._id}>
                            <Link to={{
                                pathname: 'bugs/edit',
                                state: { app, bug },
                            }}>
                                {bug.title}
                            </Link>
                        </h3>
                    ))
                }
            </main>
        </>
    );
}

export default Bugs;