import React from 'react';
import { FiEdit, FiTrash } from 'react-icons/fi';

function ShowApp({ app, edit, deleteApp, setAlpha }) {

    return (
        <>
            <div className="admin-app-title">
                <h2>{app.name}</h2>
                <div className="admin-title-actions">
                    <input
                        type="checkbox"
                        id={`alpha-check-${app._id}`}
                        name={`alpha-check-${app._id}`}
                        defaultChecked={app.alpha}
                        onChange={(event) => setAlpha(event.target.checked)}
                    />
                    <label htmlFor={`alpha-check-${app._id}`}>Alpha</label>
                    <button
                        className="icon-link"
                        title="Edit"
                        onClick={edit}
                    >
                        <FiEdit />
                    </button>
                    <button
                        className="icon-link"
                        title="Delete"
                        onClick={deleteApp}
                    >
                        <FiTrash />
                    </button>
                </div>
            </div>
            {app.urls && (
                <>
                    <h3 className="app-subtitle">URLs</h3>
                    {Object.keys(app.urls).map(key => {
                        const url = app.urls[key];
                        const shownUrl = url.length > 35 ? `${url.slice(0, 35)}...` : url;
                        return (
                            <div key={`${app._id}-${key}`} className="access-links">
                                <span>{key}:</span>
                                <a href={url}>{shownUrl}</a>
                            </div>
                        );
                    })}
                </>
            )}
        </>
    )
}

export default ShowApp;
