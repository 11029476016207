import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FiChevronLeft, FiPlus } from 'react-icons/fi';

import Header from '../../components/header';

import api from '../../services/api';

function Suggestions({ match, location }) {
    const [suggestions, setSuggestions] = useState([]);

    const { app } = location.state || {
        app: {
            _id: match.params.appId,
        },
    };

    useEffect(() => {
        (async function() {
            const result = await api.get(`/v0/apps/${app._id}/suggestions`);
            setSuggestions(result.data.suggestions);
        })()
    }, []);

    return (
        <>
            <Header
                left="/"
                right={{
                    pathname: 'suggestions/new',
                    state: { app },
                }}
            />
            <main className="main-content">
                <h2>Sugestões - {app.name}</h2>
                {suggestions
                    .filter(suggestion => !suggestion.resolved)
                    .map(suggestion => (
                        <h3 key={suggestion._id}>
                            <Link to={{
                                pathname: 'suggestions/edit',
                                state: { app, suggestion },
                            }}>
                                {suggestion.title}
                            </Link>
                        </h3>
                    ))
                }
            </main>
        </>
    );
}

export default Suggestions;